<template lang="html">
    <div class="component-wrapper d-flex justify-content-center register-wrapper">
        <div class="register-div">
            <h1 class="mb-3">Sign up at {{this.$store.state.title}}</h1>
            <b-form method="post">
                <b-input v-model="username" class="mb-2 register-input"
                         placeholder="Username"
                         type="text">
                </b-input>
                <b-input v-model="password1" class="mb-2 register-input"
                         placeholder="Password"
                         type="password"
                         minlength="8">
                </b-input>
                <b-input v-model="password2" class="mb-2 register-input"
                         placeholder="Repeat password"
                         type="password"
                         minlength="8">
                </b-input>
                <b-input v-model="email" class="mb-2 register-input"
                         placeholder="Email"
                         type="text">
                </b-input>
                <p class="mb-2" v-if="!passwordLengthMet">Passwords must be at least 8 characters long</p>
                <p class="mb-2" v-if="!equalPasswords">Passwords must match</p>
                <div class="d-flex">
                    <button v-bind:class="validForm ? 'none': 'disabled-click'" id="login-button" @click.prevent="register">
                        <b>Sign up!</b>
                    </button>
                    <div class="loading-div register">
                        <div v-if="this.showLoading" class="spinner-border" role="status">
                            <!-- <span class="sr-only">Loading...</span> -->
                        </div>
                    </div>
                </div>
            </b-form>
            <p>By registering you consent to our <a class="privacy-policy-link" target="_blank" href="/privacy-policy">privacy policy</a></p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Register',
    data() {
        return {
            username: '',
            password1: '',
            password2: '',
            email: '',
            showLoginError: false,
            showLoading: false
        }
    },
    mounted() {
        // this.refreshToken()
    },
    computed: {
        validForm() {
            return this.equalPasswords && this.passwordLengthMet
        },
        equalPasswords() {
            return this.password1 === this.password2
        },
        passwordLengthMet() {
            return this.password1.length > 7 && this.password2.length > 7
        }
    },
    methods: {
        register() {
            this.showLoading = true
            this.$http.post(this.$store.state.registrationUrl,
                {
                    'username': this.username,
                    'password1': this.password1,
                    'password2': this.password2,
                    'email': this.email
                }).then((response) => {
                    if (response.statusText === 'Created') {
                        this.authenticate()
                    }
            }).catch((error) => {
                return error
            })
            //     body: {
            //         'username': this.username,
            //         'password1': this.password1,
            //         'password2': this.password2,
            //         'email': this.email
            //     }).then((response) => {
            //         console.log(response)
            //     }).catch((error) => {
            //         console.log(error)
            //     })
            // }
        },
        authenticate() {
            const payload = {
                username: this.username,
                password: this.password1,
            }
            this.$http.post(this.$store.state.endpoints.obtainJWT, payload).then((response) => {
                this.$store.commit('updateToken', response.data.token);
                // get and set auth user
                const base = {
                    baseURL: this.$store.state.endpoints.baseUrl,
                    headers: {
                        // Set your Authorization to 'JWT', not Bearer!!!
                        Authorization: `JWT ${this.$store.state.jwt}`,
                        'Content-Type': 'application/json',
                    },
                    xhrFields: {
                        withCredentials: true,
                    },
                }
                // Even though the authentication returned a user object that can be
                // decoded, we fetch it again. This way we aren't super dependant on
                // JWT and can plug in something else.
                const axiosInstance = this.$http.create(base);
                axiosInstance({
                    url: `/users/?user__username=${this.username}`,
                    method: 'get',
                    params: {},
                }).then((response) => {
                    if (response.status === 200) {
                        this.$emit('close-login')
                        this.$store.commit('setAuthUser', {
                            authUser: response.data,
                            isAuthenticated: true,
                            username: response.data.results[0].username
                        })
                        this.$router.push({
                            name: 'ProfileRoute',
                            path: `/profile/${this.username}`,
                            params: {
                                profilename: this.username
                            }
                        })
                    }
                })
            })
            .catch((error) => {
                if (error.response.data.non_field_errors[0] === 'Unable to log in with provided credentials.') {
                    this.showLoginError = true
                }
            })
        },
    }
}
</script>

<style lang="css" scoped>
    .register-wrapper {
        height: 100%;
    }
    .register-div {
        padding: 2em;
        border-radius: 1em;
        width: 100%;
        box-shadow: 3px 3px 3px 5px var(--darkBackground);
        color: white;
    }
    #login-button {
        background: white;
        box-shadow: 3px 3px 3px var(--darkBackground),
                            -1px -1px 3px var(--background);
        border-radius: 1em;
        width: 8em;
        height: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--darkBackground);
        align-self: center;
        padding: 0.5em;
        border: none;
    }
    .login-error {
        color: red;
        font-weight: bold;
    }
    .register-input {
        width: 33%;
    }
    @media (max-width: 792px) {
        .register-input {
            width: 100%;
        }
    }
    .loading-div.register {
        margin-left: 1em;
        display: flex;
        align-items: center;
    }
    .privacy-policy-link {
        color: white;
    }
    .disabled-click {
        pointer-events:none;
    }
</style>
