<template lang="html">
  <div class="component-wrapper register-wrapper">
    <Register></Register>
  </div>
</template>

<script>
import Register from '@/components/auth/Register.vue'
export default {
    components: {
        Register
    },
    mounted() {

    }
}
</script>

<style lang="css" scoped>
.register-wrapper {
}
</style>
